import { ChevronLeftIcon, PhoneCallIcon } from 'lucide-react'
import { createContext, useCallback, useContext, useMemo, useState } from 'react'

import { Link } from '@remix-run/react'
import Logo from '../assets/images/logo.svg?react'

export interface HeaderContextValue {
  mobileBackButton: {
    onClick?: string | (() => void)
    setHandler: (handler: string | (() => void) | undefined) => void
  }
}

export let HeaderContext = createContext<HeaderContextValue>({
  mobileBackButton: {
    onClick: () => {
      throw new Error('onClick is not implemented')
    },

    setHandler: () => {
      throw new Error('setHandler is not implemented')
    }
  }
})

export interface HeaderContextProviderProps {
  children: React.ReactNode
}

export function HeaderContextProvider({ children }: HeaderContextProviderProps): React.ReactNode {
  let [mobileBackButtonHandler, setMobileBackButtonHandler] =
    useState<HeaderContextValue['mobileBackButton']['onClick']>()

  let setHandler = useCallback<HeaderContextValue['mobileBackButton']['setHandler']>(handler => {
    setMobileBackButtonHandler(() => handler)
  }, [])

  let value = useMemo(
    () => ({
      mobileBackButton: {
        onClick: mobileBackButtonHandler,
        setHandler
      }
    }),
    [mobileBackButtonHandler, setHandler]
  )

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
}

export function useHeaderContext(): HeaderContextValue {
  return useContext(HeaderContext)
}

export function Header(): React.ReactNode {
  let { mobileBackButton } = useHeaderContext()

  return (
    <header className='px-4-safe pt-6-safe border-b pb-6'>
      <div className='flex flex-row items-center justify-between lg:container lg:mx-auto'>
        <div>
          <Logo className='h-9 md:h-12' />
        </div>

        <div className='hidden flex-row items-center justify-start gap-8 lg:flex'>
          <a
            href='#'
            className='hidden rounded border border-red-400 bg-white px-4 py-2 text-red-500 md:block'
          >
            Найти свою запись
          </a>

          <div className='flex flex-col'>
            <div className='flex flex-row items-center justify-start gap-3'>
              <PhoneCallIcon
                size='24'
                className='text-blue-500'
              />

              <a
                href='#'
                className='text-2xl text-gray-800'
              >
                (812) 325-77-88
              </a>
            </div>

            <div className='flex flex-row items-start justify-end'>
              <div className='grid grid-cols-[max-content_1fr] gap-x-1 text-sm text-gray-500'>
                <span>пн.-пт.</span>
                <span>08:00–21:00</span>
                <span>сб.-вс.</span>
                <span>09:00–18:00</span>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-row items-center justify-start gap-3 md:gap-4 lg:hidden'>
          {typeof mobileBackButton.onClick === 'function' && (
            <button
              type='button'
              className='flex size-8 items-center justify-center rounded bg-red-500 text-white'
              onClick={mobileBackButton.onClick}
            >
              <ChevronLeftIcon size='28' />
            </button>
          )}

          {typeof mobileBackButton.onClick === 'string' && (
            <Link
              to={mobileBackButton.onClick}
              className='flex size-8 items-center justify-center rounded bg-red-500 text-white'
            >
              <ChevronLeftIcon size='28' />
            </Link>
          )}

          <PhoneCallIcon
            size='28'
            className='text-red-500'
          />
        </div>
      </div>
    </header>
  )
}
