import * as React from "react";
const SvgLogo = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, viewBox: "0 0 994 201", ...props }, /* @__PURE__ */ React.createElement("g", { transform: "translate(-741.185 -1653.86)" }, /* @__PURE__ */ React.createElement("path", { d: "M741.185 1653.86h993.967v200.146H741.185z", style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("clipPath", { id: "a" }, /* @__PURE__ */ React.createElement("path", { d: "M741.185 1653.86h993.967v200.146H741.185z" })), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#a)" }, /* @__PURE__ */ React.createElement("path", { d: "M337.156 97.063c-.047 1.219 0 2.582-.777 3.555-.539.683-1.414 1.172-2.289 1.121-3.36-.148-3.457-5.262-3.457-8.816.047-3.555-.293-9.204 3.308-9.254.879-.047 1.805.586 2.34 1.316.781 1.07.781 2.434.828 3.75.047 1.414.145 2.824.145 4.188 0 1.316-.098 2.679-.098 4.14m-2.047 7.695c3.121.34 7.844-2.433 7.696-12.078-.192-10.082-4.918-11.886-7.547-11.836-1.461 0-3.457.684-4.824 2.582v-6.73h1.918v-2.141h-8.832v2.141h1.898v25.328h-1.898v2.141h6.914v-2.524c1.121 1.703 2.925 2.973 4.675 3.117", style: {
  fill: "#e30016"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2165.133)" }), /* @__PURE__ */ React.createElement("path", { d: "M321.973 77.929h-2.535v18.754h1.968v2.141h-16.652v-2.141h2.762L305.32 82c-.523-2.934-2.504-4.086-3.34-4.059l-1.332-.012v-2.441h21.325zm-7.762 18.754V77.929h-6.602c.813 1.387 1.301 4.016 1.465 5.313l2.031 13.441z", style: {
  fill: "#e30016"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2142.879)" }), /* @__PURE__ */ React.createElement("path", { d: "M276.359 75.488v2.445h-1.964v18.528s.796-.004 1.515-.004c.715 0 1.266-.793 1.465-1.371.355-1.043.395-3.223.395-3.223h3.097v6.965h-17.945v-6.965h3.035s.055 2.219.352 3.223c.16.621.812 1.375 1.3 1.375h1.778V77.933h-2.078v-2.445z", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2142.896)" }), /* @__PURE__ */ React.createElement("path", { d: "m254.098 96.524-.063-18.528h-2.019v-2.445h9.168v2.445h-2.028V96.75h2.028v2.141h-17.207V96.75h1.968V85.836c0-2.98-.093-7.16-2.304-7.816-1.067-.313-1.469.222-1.469.644 0 .082.586.34.863.731.309.441.391 1.164.391 1.562 0 1.465-1.25 2.574-2.602 2.574-1.344-.113-2.644-1.234-2.808-3.156-.168-2.355 1.683-4.887 5.172-4.836 5.57.059 6.359 5.184 6.359 10.86v10.125z", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2143.158)" }), /* @__PURE__ */ React.createElement("path", { d: "m237.242 77.324-.765.012c-.672.035-1.071.586-1.118 1.516-.015.507-.007 1.082-.007 1.648v11.582c0 5.852-4.782 6.695-6.813 6.695-4.273.114-7.59-2.699-7.699-5.574-.113-1.684.894-3.144 2.527-3.199 1.406-.063 2.531.898 2.813 2.187.058.399.043 1.18-.172 1.594-.426.82-.637.914-.59 1.051.195.555 1.102 1.41 2.508 1.351 2.304 0 2.355-1.519 2.355-1.91v-4.781c-8.863-.488-11.308-3.937-11.207-8.652.082-3.809 3.356-6.028 6.207-5.977 1.801.039 3.824 1.184 5 2.481.457-1.012 1.641-2.422 4.168-2.481.903 0 2.789.016 2.789.016zm-6.961 9.473v-4.496c0-3.043-2.242-4.219-3.644-4.114-.508.063-2.825.336-2.84 3.239-.035 4.719 4.23 5.238 6.484 5.371", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2140.358)" }), /* @__PURE__ */ React.createElement("path", { d: "m362.633 77.324-.77.012c-.672.035-1.07.586-1.113 1.516-.02.507-.016 1.082-.016 1.648v11.582c0 5.852-4.777 6.695-6.804 6.695-4.274.114-7.59-2.699-7.703-5.574-.114-1.684.898-3.144 2.527-3.199 1.406-.063 2.535.898 2.812 2.187.059.399.043 1.18-.168 1.594-.429.82-.64.914-.589 1.051.195.555 1.097 1.41 2.503 1.351 2.309 0 2.36-1.519 2.36-1.91v-4.781c-8.867-.488-11.313-3.937-11.207-8.652.078-3.809 3.351-6.028 6.207-5.977 1.801.039 3.82 1.184 5 2.481.453-1.012 1.637-2.422 4.168-2.481.894 0 2.789.016 2.789.016zm-6.961 9.473v-4.496c0-3.043-2.242-4.219-3.649-4.114-.507.063-2.824.336-2.839 3.239-.032 4.719 4.234 5.238 6.488 5.371", style: {
  fill: "#e30016"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2140.358)" }), /* @__PURE__ */ React.createElement("path", { d: "M214.426 88.758v10.066h-21.254v-2.645h3.262V66.234h-3.262v-2.441h14.625c2.644 0 10.18 1.676 9.672 11.125-.168 2.926-1.18 6.808-5.621 8.554-1.973.782-4.114.84-6.246.95-1.012 0-2.082.058-3.153 0v11.816h3.828c2.473 0 3.563-.941 4.121-2.629.286-1.07.414-2.66.43-4.851zm-11.977-21.719v14.578c7.879.672 8.27-2.703 8.379-6.812.117-3.657.059-9.114-8.379-7.766", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2094.15)" }), /* @__PURE__ */ React.createElement("path", { d: "M163.973 70.86c.207.066.422.121.648.168-.875.738-2.258 2.222-3.785 5.078-2.508 4.793-2.508 7.746-.559 6.742 1.563-.824 2.45-1.82 3.266-3.801-.465 3.715.492 5.598 2.086 4.473 1.898-1.395 2.512-2.844 2.512-6.414 0-2.52-.43-4.95-.676-6.121.332-.063.668-.137 1.012-.235.125-.039.246-.082.375-.125-.454.985-.887 2.617-.711 5.145.336 4.793 2.285 7.191 3.902 5.687 1.254-1.215 1.625-2.332 1.211-4.258 1.543 2.754 3.269 3.625 4.027 2.145.946-1.777.672-3.148-1.195-5.863-1.473-2.043-3.281-3.817-4.027-4.516.234-.18.464-.355.679-.547.504.727 1.938 2.703 3.766 4.395 2.285 2.066 3.621 2.566 5.629 2.066 2.062-.449.781-2.844-3.344-5.129-2.863-1.59-4.758-1.75-5.652-1.703.199-.191.383-.387.554-.59 1.114.469 3.543 1.43 6.157 2.125 3.625.946 5.297.891 7.191-.113 1.5-.793.129-1.867-3.301-2.527 2.778-.176 4.16-.668 5.477-1.711 1.199-.953-.438-1.535-3.926-1.403 3.508-.457 5.152-1.097 6.477-2.324 1.289-1.246-1.461-1.641-6.5-.867a.6.6 0 0 1 .101-.031c4.047-1.352 6.352-2.348 7.805-3.45v20.586H181.02v12.153h-23.641V79.028c.527-.528 1.094-1.141 1.73-1.864 2.274-2.59 4.192-5.316 4.864-6.304", style: {
  fill: "#e30016"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2029.291)" }), /* @__PURE__ */ React.createElement("path", { d: "M173.441 87.317c1.657-.582 4.5-1.605 7.477-2.805-3.363 1.617-5.262 2.883-6.332 3.75-.281-.386-.668-.703-1.145-.945", style: {
  fill: "#e30016"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2136.471)" }), /* @__PURE__ */ React.createElement("path", { d: "M170.164 75.258c.445-.211.867-.449 1.27-.703-.141.617-.235 1.637.054 3.105-.5-.996-.984-1.84-1.324-2.402", style: {
  fill: "#e30016"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2050.808)" }), /* @__PURE__ */ React.createElement("path", { d: "M180.902 81.223c-3.132.465-5.047 1.082-6.172 1.574.196-.445.321-.894.36-1.336 1.426-.015 3.523-.074 5.812-.238", style: {
  fill: "#e30016"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2099.996)" }), /* @__PURE__ */ React.createElement("path", { d: "M165.48 73.926c.297.016.598.016.915-.004-.368.446-.801 1.094-1.231 2.008.148-.824.254-1.516.316-2.004", style: {
  fill: "#e30016"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2040.962)" }), /* @__PURE__ */ React.createElement("path", { d: "M178.359 92.949c.985-.692 2.008-1.426 3.012-2.184.649-.48 1.223-.914 1.742-1.316h2.551c-1.707.691-3.781 1.652-6.109 2.867-.422.219-.821.43-1.196.633", style: {
  fill: "#e30016"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2176.571)" }), /* @__PURE__ */ React.createElement("path", { d: "M189.84 90.676h3.332v.336c-1.481.164-3.809.812-6.703 1.937 1.722-.855 2.734-1.543 3.371-2.273", style: {
  fill: "#e30016"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2181.683)" }), /* @__PURE__ */ React.createElement("path", { d: "M147.53 68.001c-.036 2.268-.296 3.76-.719 4.158l.719 1.204 1.864-1.112c-.932-.717-1.486-1.883-1.864-4.25", style: {
  fill: "#008434"
}, transform: "scale(-4.16666)rotate(-59.192 -440.5562 119.3806)" }), /* @__PURE__ */ React.createElement("path", { d: "M145.227 76.246v-2.121c1.425-.168 3.32-.641 5.949-1.457-3 1.363-5.114 2.66-5.949 3.578", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2037.054)" }), /* @__PURE__ */ React.createElement("path", { d: "M162.844 69.309c.203.144.426.265.668.371-.996.375-3.043 1.418-6.301 4.305-1.133.992-2.09 1.914-2.879 2.753H150.5c1.184-.472 2.66-1.25 4.648-2.418 3.184-1.871 6.219-3.964 7.696-5.011", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2025.108)" }), /* @__PURE__ */ React.createElement("path", { d: "M172.449 103.817c-.199-.047-.406-.09-.621-.118.832-1.214 2.649-3.945 4.059-6.644 1.894-3.625 2.066-4.965.84-5.801-1.157-.801-3.309 1.754-4.864 5.938.004-.028.012-.055.016-.082.891-4.016.691-5.411-.887-6.024-1.523-.609-3.25 3.07-3.347 8.367-.043 2.336.289 3.727.671 4.555a.4.4 0 0 0-.066.016c-.359.109-.707.238-1.051.379.086-1.211.274-4.438.055-7.5-.328-3.868-.93-5.161-2.551-5.536-1.668-.324-2.226 3.383-.836 8.383.953 3.305 2.082 4.488 2.738 4.918-.281.133-.558.277-.828.434-.507-1.203-1.535-3.449-2.879-5.485-1.894-3.007-3.121-3.789-5.074-3.566-.172.02-.316.07-.445.133V84.531h23.641v10.805c-1.051.824-2.188 1.77-3.387 2.832-3.852 3.379-4.899 4.996-5.184 5.649", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2206.716)" }), /* @__PURE__ */ React.createElement("path", { d: "M160.18 81.378c.918-.121 1.695-.23 2.281-.316-.102.156-.191.312-.277.473-.465-.09-1.114-.157-2.004-.157", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2094.066)" }), /* @__PURE__ */ React.createElement("path", { d: "M152.695 78.632c.496-.035.993-.078 1.493-.121-.129.024-.247.043-.376.067-3.492.636-6.441 1.441-8.585 2.246v-2.59c.933.672 2.929.781 7.468.398", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2079.321)" }), /* @__PURE__ */ React.createElement("path", { d: "M169.926 88.586c.25-.707.566-1.625.883-2.645-.192 1.293-.094 2.098.078 2.586-.309 0-.633.023-.961.059", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2143.775)" }), /* @__PURE__ */ React.createElement("path", { d: "M162.207 76.867c-.992.172-2.535.586-4.844 1.524 1.75-.993 3.278-1.918 4.34-2.575.098.391.266.746.504 1.051", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2059.108)" }), /* @__PURE__ */ React.createElement("path", { d: "M160.445 88.132c1.867 1.813 3.317 2.567 4.285 2.879-.148.106-.292.219-.433.332-1.074-.636-3.777-2.183-6.75-3.433-3.793-1.617-5.574-1.84-7.473-1.059-1.949.836.891 2.899 6.801 4.348 3.512.851 5.625.855 6.777.715-.257.25-.496.504-.711.769-.839-.359-2.691-.804-6.789-.871-5.359-.129-9.586.406-10.925 1.137V81.91h11.695c-.434 1.117.773 3.558 3.523 6.222", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2145.158)" }), /* @__PURE__ */ React.createElement("path", { d: "M161.652 79.757c-.48.028-1.093.106-1.871.266.762-.293 1.434-.559 1.977-.773-.047.168-.082.339-.106.507", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2080.216)" }), /* @__PURE__ */ React.createElement("path", { d: "M280.867 88.145v10.18h3.02v-1.176c0-.539.543-.219.582-.187.043.031.078.062.117.093 1.492 1.168 3.371 2 6.266 2 6.136.047 9.347-4.187 9.398-8.183.047-3.895-2.922-7.254-7.062-8.278 3.652-.925 8.476-3.312 8.523-9.5.051-6.574-5.016-10.082-11.641-9.933-7.695.047-10.519 4.625-10.472 8.23 0 2.727 1.754 4.336 3.656 4.285 1.703 0 3.312-1.414 3.359-3.507 0-1.02-.343-2.094-.972-2.871-.196-.145-.344-.293-.442-.489-.097-.195-.148-.39-.148-.535.051-1.023 2.34-2.68 4.531-2.582 2.828.098 5.457 2.387 5.406 7.402-.047 6.09-3.359 8.477-9.25 8.184v2.582c7.887-.195 8.278 3.75 8.278 6.82-.047 1.653-.243 5.551-4.235 5.551-4.238.047-5.117-4.094-5.312-8.133z", style: {
  fill: "#e30016"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2092.054)" }), /* @__PURE__ */ React.createElement("path", { d: "M373.379 78.067c.73.05 1.512 0 2.141.343 1.656.778 1.707 3.176 1.707 3.954 0 2.921-.977 4.82-5.457 4.433v-8.68c.488-.05 1.074-.05 1.609-.05m-.098 11.261c3.457-.046 3.508 3.02 3.508 3.653 0 .926-.051 3.558-3.508 3.508h-1.511v-7.161zm-8.765-11.406h2.043v18.762h-2.043v2.144h10.226c4.528 0 6.77-2.632 6.965-5.164.195-3.117-2.535-4.921-4.969-5.457 3.41-.437 6.09-2.726 5.992-6.035-.097-6.043-6.332-6.695-7.988-6.695h-10.226z", style: {
  fill: "#e30016"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2142.85)" }), /* @__PURE__ */ React.createElement("path", { d: "m220.184 105.094.668-2.926h.011l.66 2.926h1.122v-3.711h-.723v3.102h-.008l-.691-3.102h-.762l-.676 3.102h-.012v-3.102h-.718v3.711zM226.965 103.688h1.449v1.406h.774v-3.711h-.774v1.664h-1.449v-1.664h-.77v3.711h.77z", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2276.9)" }), /* @__PURE__ */ React.createElement("path", { d: "M234.391 101.941c.418 0 1.007.258 1.007 1.297s-.589 1.297-1.007 1.297-1.008-.258-1.008-1.297.59-1.297 1.008-1.297m0 3.25c.492 0 1.777-.215 1.777-1.953 0-1.739-1.285-1.953-1.777-1.953-.493 0-1.782.214-1.782 1.953 0 1.738 1.289 1.953 1.782 1.953", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2276.896)" }), /* @__PURE__ */ React.createElement("path", { d: "M242.238 105.094v-.64h-1.871v-3.071h-.773v3.711z", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2276.9)" }), /* @__PURE__ */ React.createElement("path", { d: "M247.215 101.941c.422 0 1.008.258 1.008 1.297s-.586 1.297-1.008 1.297c-.418 0-1.004-.258-1.004-1.297s.586-1.297 1.004-1.297m0 3.25c.492 0 1.781-.215 1.781-1.953 0-1.739-1.289-1.953-1.781-1.953-.488 0-1.777.214-1.777 1.953 0 1.738 1.289 1.953 1.777 1.953", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2276.896)" }), /* @__PURE__ */ React.createElement("path", { d: "M255.41 105.094v-3.711h-.769v3.071h-1.45v-3.071h-.769v3.711zM259.734 103.36h.723c.512 0 .578.347.578.578 0 .363-.199.516-.68.516h-.621zm0-1.977h-.769v3.711h1.695c.719 0 1.145-.457 1.145-1.187 0-.332-.184-1.184-1.18-1.184h-.891z", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2276.9)" }), /* @__PURE__ */ React.createElement("path", { d: "M266.883 101.941c.418 0 1.008.258 1.008 1.297s-.59 1.297-1.008 1.297-1.008-.258-1.008-1.297.59-1.297 1.008-1.297m0 3.25c.492 0 1.777-.215 1.777-1.953 0-1.739-1.285-1.953-1.777-1.953s-1.778.214-1.778 1.953c0 1.738 1.286 1.953 1.778 1.953", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2276.896)" }), /* @__PURE__ */ React.createElement("path", { d: "M274.422 104.094v-1.687c.394 0 .922.136.922.832 0 .699-.528.855-.922.855m-.77-1.687v1.687c-.394 0-.922-.156-.922-.855 0-.696.528-.832.922-.832m.77 2.308c.66 0 1.695-.347 1.695-1.476 0-1.133-1.035-1.457-1.695-1.457v-.399h-.77v.399c-.66 0-1.691.324-1.691 1.457 0 1.129 1.031 1.476 1.691 1.476v.379h.77zM280.312 102.508h.004l1.481 2.586h.867v-3.711h-.773v2.641h-.008l-1.516-2.641h-.828v3.711h.773z", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2276.9)" }), /* @__PURE__ */ React.createElement("path", { d: "M289.188 105.191v-3.711h-.774v3.071h-1.047v-1.582c0-.961-.234-1.586-.965-1.586-.175 0-.34.015-.472.054v.594c.047-.016.129-.031.23-.031.348 0 .438.246.438 1.047v2.144z", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2277.304)" }), /* @__PURE__ */ React.createElement("path", { d: "M293.512 102.02h.875c.371 0 .586.23.586.539 0 .328-.239.488-.586.488h-.875zm0 1.668h.968c.715 0 1.266-.434 1.266-1.172 0-.73-.539-1.133-1.277-1.133h-1.731v3.711h.774zM299.945 103.688h1.446v1.406h.773v-3.711h-.773v1.664h-1.446v-1.664h-.773v3.711h.773zM306.742 102.789h.992l-.507 1.465h-.012zm.938 2.305 1.3-3.711h-.835l-.243.766h-1.363l-.262-.766h-.812l1.316 3.711zM314.453 104.454h-.957c-.394 0-.543-.266-.543-.465 0-.305.199-.516.602-.516h.898zm.77-3.071h-.77v1.453h-.574l-.859-1.453h-.907l.907 1.524c-.418.136-.84.468-.84 1.058 0 .586.453 1.129 1.168 1.129h1.875zM340.918 103.586l1.414 1.508h1.004l-1.516-1.519 1.602-2.192h-1l-1.145 1.629-.359-.371v-1.258h-.773v3.711h.773z", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2276.9)" }), /* @__PURE__ */ React.createElement("path", { d: "M349.613 105.191v-3.711h-.773v3.071h-1.047v-1.582c0-.961-.234-1.586-.965-1.586-.176 0-.34.015-.473.054v.594c.047-.016.129-.031.231-.031.348 0 .434.246.434 1.047v2.144z", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2277.304)" }), /* @__PURE__ */ React.createElement("path", { d: "M353.938 102.508h.003l1.477 2.586h.871v-3.711h-.773v2.641h-.008l-1.516-2.641h-.828v3.711h.774zM360.613 103.688h1.446v1.406h.773v-3.711h-.773v1.664h-1.446v-1.664h-.773v3.711h.773zM367.156 102.508h.004l1.481 2.586h.867v-3.711h-.77v2.641h-.011l-1.516-2.641h-.828v3.711h.773zM373.832 103.586l1.418 1.508h1l-1.512-1.519 1.602-2.192h-.996l-1.149 1.629-.363-.371v-1.258h-.77v3.711h.77zM380.586 102.789h.992l-.504 1.465h-.012zm.937 2.305 1.301-3.711h-.836l-.242.766h-1.363l-.262-.766h-.812l1.32 3.711z", style: {
  fill: "#008434"
}, transform: "matrix(4.16667 0 0 -4.16667 140.24 2276.9)" }))));
export default SvgLogo;
